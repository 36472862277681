import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './App.css';

function App() {
  return (
    <div className="App">
    <Container>
    <Row className='hr'>
  <Col xs={4} md={4}>
<img src="https://gyrostownorderonline.com/img/gyros-logo-for-web.png"/>
</Col>
<Col xs={4} md={4}>

</Col>
<Col xs={4} md={4}>

</Col>
</Row>
</Container>


<Container>
  <Row className='sec-two' style={{ maxWidth: '1000px'}}>

<Col md={6}>
    <Card style={{ width: '100%' }}>
    <Card.Title className='cardtitle'>Gyros Food Truck
    </Card.Title>
    <Card.Img variant="top" src="/images/20200425133808762_1587821888762_56254575645015528409.jpg"/>
    <Card.Body>
      <Card.Title>2009 Larimer Street <br/>
      Denver, Colorado 80205 <br/>
      UNITED STATES</Card.Title>
      <Card.Text>
      <a href="https://denver.gyrosfoodtruckorderonline.com/">Order Online</a>
      </Card.Text>
    </Card.Body>
  </Card>
  </Col>

<Col md={6}>
  <Card style={{ width: '100%' }}>
  <Card.Title className='cardtitle'>Gyros Food Truck </Card.Title>
  <Card.Img variant="top" src="https://store.diningtek.com/contents/20200425133808762_1587821888762_56254575645015528409"/>
  <Card.Body>
    <Card.Title>1216 20th st<br/>
    Denver, Colorado 80202 <br/>
    UNITED STATES </Card.Title>
    <Card.Text>
  <a href="https://denver1216.gyrosfoodtruckorderonline.com/">Order Online</a>
    </Card.Text>
  </Card.Body>
</Card>
</Col>


  </Row>
  </Container>
    </div>
  );
}

export default App;
